import { useRouter } from 'next/router'

const testPathnames = {
  2: '/2',
  new: '/new',
};

export const useABTest = () => {
  const { locale, pathname } = useRouter();

  const isABTestForPathname2 = pathname === testPathnames[2] && locale === 'ru';
  const isABTestForPathnameNew = pathname === testPathnames.new && locale === 'ru';

  return { isABTestForPathname2, isABTestForPathnameNew };
};
