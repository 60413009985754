import React from 'react'
import NextLink from 'next/link'
import PropTypes from 'prop-types'

import { getIconFromMap } from '@utils/getIconFromMap'

import styles from './link.module.scss'

const LinkSecondLevel = ({id, title, description, link}) => {
  const icon = getIconFromMap(id)

  return (
    <div className={styles.rootSecond}>
      {icon && (
        <div className={styles.iconSecondLevel}>
          {icon}
        </div>
      )}

      <div className={styles.textContentSecondLevel}>
        <span className={styles.titleSecondLevel}>{title}</span>

        <span className={styles.descriptionSecondLevel}>{description}</span>
      </div>

      <NextLink href={link} className={styles.link} ></NextLink>

    </div>
  )
}

LinkSecondLevel.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,

}

export default LinkSecondLevel
