import PropTypes from 'prop-types'
import cns from 'classnames'

import useLanguageUrl from '@utils/useLanguageUrl'
import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'
import { useABTest } from '@utils/useABTest'

import styles from './actions.module.scss'

export default function Actions({ pageId }) {
  const t = useFormatMessage()
  const langUrl = useLanguageUrl()
  const langUrlSignin = useLanguageUrl('signin')
  const { isABTestForPathname2 } = useABTest()

  const handleLoginButtonClick = () => {
    $amplitude('[Landing] Login Button Click', {
      page_name: pageId,
      block_name: 'header',
    })
  }

  const handleRegistrationButtonClick = () => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'header',
    })
  }

  return (
    <div className={styles.buttons}>
      <a
        className={cns(
          styles.button,
          styles.signup,
          `button button--small button--primary`
        )}
        href={langUrl}
        onClick={handleRegistrationButtonClick}
        rel="nofollow"
      >
        {t('tryFree')}
      </a>

      <a
        className={cns(styles.signin)}
        href={langUrlSignin}
        onClick={handleLoginButtonClick}
        rel="nofollow"
      >
        {t(`nav.links.login`)}
      </a>
    </div>
  )
}

Actions.propTypes = {
  pageId: PropTypes.string,
}
