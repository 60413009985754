import { useState, useRef, useEffect } from 'react'

const queries = [
  '(max-width: 1199px)',
  '(max-width: 767px)',
  '(max-width: 991px)',
  '(min-width: 1200px)',
]

export const useMatchMedia = () => {
  const mediaQueryLists = useRef([])

  const getValues = () => mediaQueryLists.current.map(list => list.matches)

  const [values, setValues] = useState([])

  useEffect(() => {
    mediaQueryLists.current = queries.map(query => window.matchMedia(query))
  }, [])

  useEffect(() => {
    setValues(getValues)

    const handler = () => setValues(getValues)

    mediaQueryLists.current.map(list => (list.onchange = handler))
    return () => {
      mediaQueryLists.current.map(list => (list.onchange = handler))
    }
  }, [])

  return ['isMobile', 'isMobileButNotTablet', 'isTablet', 'isDesktop'].reduce(
    (acc, screen, index) => ({
      ...acc,
      [screen]: values[index],
    }),
    {}
  )
}
