import IconAutoposting from '@icons/nav/autoposting.svg'
import IconBook from '@icons/nav/book.svg'
import IconChart from '@icons/nav/monitoring.svg'
import IconCheck from '@icons/nav/check.svg'
import IconComments from '@icons/nav/comments.svg'
import IconComparison from '@icons/nav/comparison.svg'
import IconKPI from '@icons/nav/kpi.svg'
import IconPostingIG from '@icons/socials/socialsRounded/ig-rounded-secondLevel.svg'
import IconPostingOK from '@icons/socials/socialsRounded/ok-rounded-secondLevel.svg'
import IconPostingVK from '@icons/socials/socialsRounded/vk-rounded-secondLevel.svg'
import IconPostingTG from '@icons/socials/socialsRounded/tg-rounded-secondLevel.svg'
import IconPostingTT from '@icons/socials/socialsRounded/tt-rounded-secondLevel.svg'
import IconPostingFB from '@icons/socials/socialsRounded/fb-rounded-secondLevel.svg'
import IconStatistika from '@icons/socials/socialsRounded/statistika-rounded.svg'
import IconFAQ from '@icons/faq.svg'

const iconsMap = {
  autoposting: IconAutoposting,
  'account-stats': IconChart,
  reports: IconBook,
  monitoring: IconComments,
  kpi: IconKPI,
  comparing: IconComparison,
  bloggersCheck: IconCheck,
  expressAuditing: IconAutoposting,
  'vk-analytics': IconPostingVK,
  'fb-analytics': IconPostingFB,
  'instagram-analytics': IconPostingIG,
  'statistika-akkaunta-tik-tok': IconPostingTT,
  'statistika-telegram': IconPostingTG,
  'statistika-odnoklassniki': IconPostingOK,
  'autoposting-instagram': IconPostingIG,
  'autoposting-odnoklassniki': IconPostingOK,
  'avtoposting-vk': IconPostingVK,
  'avtoposting-v-telegram': IconPostingTG,
  'statistika': IconStatistika,
  'faq': IconFAQ,
}

export const getIconFromMap = id => {
  const Icon = iconsMap[id]
  return Icon ? <Icon className={`navIcon_${id}`} /> : undefined
}
