export const navDataLinksProducts = [
  {
    id: 'autoposting',
    link: '/autoposting',
    linkforBr: '/agendamento',
  },
  {
    id: 'statistika',
    link: '/account-stats',
    linkforBr: '/status-da-conta',
  },
  {
    id: 'reports',
    link: '/reports',
    linkforBr: '/relatorios',
  },
  {
    id: 'monitoring',
    link: '/monitoring',
    linkforBr: '/engajamento',
  },
  {
    id: 'faq',
    link: 'https://livedune.com/br/blog/perguntas-frequentes/',
    linkforBr: 'https://livedune.com/br/blog/perguntas-frequentes/',
  }
]

export const navDataLinksResources = [
  {
    id: 'researches',
    link: 'https://livedune.com/ru/blog/issledovaniya',
    isBlank: true,
  },
  {
    id: 'blog',
    link: 'https://livedune.com/ru/blog',
    linkforEn: 'https://livedune.com/blog/',
    linkforBr: 'https://livedune.com/br/blog/',
    linkforEs: 'https://livedune.com/es/blog/',
    isBlank: true,
  },
  {
    id: 'knowledgeBase',
    link: 'https://wiki.livedune.com',
    isBlank: true,
  },
  {
    id: 'YTRating',
    link: '/youtube',
    isBlank: true,
  },
  {
    id: 'VKRating',
    link: '/vk',
    isBlank: true,
  },
  {
    id: 'LJRating',
    link: '/livejournal/blogger',
    isBlank: true,
  }
]

export const navDataLinksTools = [
  {
    id: 'kpi',
    link: '/kpi',
    linkforBr: '/kpi',
  },
  {
    id: 'bloggersCheck',
    link: '/bloggers-check',
    linkforBr: '/analise-influenciadores',
  },
  {
    id: 'expressAuditing',
    link: '/audit-instagram',
    linkforBr: '/auditoria-instagram',
  },
]

export const navDataLinksLanguages = [
  {
    id: 'ru',
    locale: 'ru',
    link: '/ru',
  },
  {
    id: 'en',
    locale: 'en',
    link: '/en',
  },
  {
    id: 'br',
    locale: 'br',
    link: '/br',
  },
  {
    id: 'kz',
    locale: 'kz',
    link: '/kz',
  },
  {
    id: 'ua',
    locale: 'ua',
    link: '/ua',
  },
]

export const navDataLinks = [
  {
    id: 'products',
    pages: navDataLinksProducts,
  },
  {
    id: 'resources',
    pages: navDataLinksResources,
  },
  {
    id: 'tools',
    pages: navDataLinksTools,
  },
  {
    id: 'pricing',
    link: '/pricing',
    linkforBr: '/precos',
  },
  {
    id: 'Blog',
    link: 'https://livedune.com/br/blog/',
    linkforBr: 'https://livedune.com/br/blog/',
    isBlank: true,
  },
]

export const navDataLinksProductsAutoposting = [
  {
    id: 'autoposting-instagram',
    link: '/autoposting-instagram',
    linkforBr: '/agendamento-instagram',
  },
  {
    id: 'autoposting-odnoklassniki',
    link: '/autoposting-odnoklassniki',
  },
  {
    id: 'avtoposting-v-telegram',
    link: '/avtoposting-v-telegram',
    linkforBr: '/agendamento-telegram',
  },
  {
    id: 'avtoposting-vk',
    link: '/avtoposting-vk',
  },
]

export const navDataLinksProductsStatistika = [
  {
    id: 'instagram-analytics',
    link: '/instagram-analytics',
    linkforBr: '/estatistica-instagram',
  },
  {
    id: 'vk-analytics',
    link: '/vk-analytics',
  },
  {
    id: 'statistika-akkaunta-tik-tok',
    link: '/statistika-akkaunta-tik-tok',
    linkforBr: '/estatistica-tik-tok',
  },
  {
    id: 'statistika-telegram',
    link: '/statistika-telegram',
    linkforBr: '/estatistica-telegram',
  },
  {
    id: 'statistika-odnoklassniki',
    link: '/statistika-odnoklassniki',
  },
  {
    id: 'fb-analytics',
    link: '/statistika-facebook',
    linkforBr: '/estatistica-facebook',
  },
]
