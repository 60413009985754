export const getLinkPrefix = (locale, link) => {
  if (link[0] === '/') {

    if (
      ['/youtube',
        '/vk',
        '/livejournal/blogger',
        '/telegram'].includes(link)
    ) {
      return `https://livedune.com/ru${link}`
    }

    if (locale !== 'en') {
      return `/${locale}${link}`
    }
  }

  return link
  return link[0] === '/'
    ? locale !== 'en'
      ? `/${locale}${link}`
      : link // при en префикс не нужен
    : link
}
