import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import cns from 'classnames'

import CheckMark from '@icons/inputDone.svg'
import ErrorMark from '@icons/inputError.svg'

import useFormatMessage from '@utils/useFormatMessage'

import styles from './DemoModal.module.scss'
import $amplitude from '@utils/amplitude'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^[0-9+]*$/

const DemoModal = ({ isOpen, handleOpenSuccessModal, onClose, locale }) => {
  const t = useFormatMessage()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [company, setCompany] = useState('')
  const [site, setSite] = useState('')
  const [nameError, setNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [isNameValid, setIsNameValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isPhoneValid, setIsPhoneValid] = useState(false)

  const handleNameChange = e => {
    const value = e.target.value
    setName(value)
  }

  const handleEmailChange = e => {
    const value = e.target.value
    setEmail(value)
  }

  const handlePhoneChange = e => {
    const value = e.target.value
    setPhone(value)
  }

  const handleCompanyChange = e => {
    const value = e.target.value
    setCompany(value)
  }

  const handleSiteChange = e => {
    const value = e.target.value
    setSite(value)
  }

  const handleNameBlur = () => {
    if (!name.trim()) {
      setNameError('')
      setIsNameValid(false)
    } else if (name.length < 2 || name.length > 30) {
      setNameError(t('demoModal.input.length.error'))
      setIsNameValid(false)
    } else {
      setNameError('')
      setIsNameValid(true)
    }
  }

  const handleEmailBlur = () => {
    if (!email.trim()) {
      setEmailError('')
      setIsEmailValid(false)
    } else if (!emailRegex.test(email)) {
      setEmailError(t('demoModal.input.email.error'))
      setIsEmailValid(false)
    } else {
      setEmailError('')
      setIsEmailValid(true)
    }
  }

  const handlePhoneBlur = () => {
    if (!phone.trim()) {
      setPhoneError('')
      setIsPhoneValid(false)
    } else if (!phoneRegex.test(phone)) {
      setPhoneError(t('demoModal.input.phone.error'))
      setIsPhoneValid(false)
    } else {
      setPhoneError('')
      setIsPhoneValid(true)
    }
  }

  useEffect(() => {
    const toggleBodyScroll = shouldBlockScroll => {
      document.body.style.overflow = shouldBlockScroll ? 'hidden' : ''
    }
    toggleBodyScroll(isOpen)

    return () => {
      toggleBodyScroll(false)
    }
  }, [isOpen])

  const handleFormSubmit = async e => {
    e.preventDefault()
    let hasErrors = false

    $amplitude('[Landing] Enrol For Demo Button Click')

    if (!name) {
      setNameError(t('demoModal.input.empty.error'))
      setIsNameValid(false)
      hasErrors = true
    } else if (name.length < 2 || name.length > 30) {
      setNameError(t('demoModal.input.length.error'))
      setIsNameValid(false)
      hasErrors = true
    } else {
      setNameError('')
      setIsNameValid(true)
    }

    if (!email) {
      setEmailError(t('demoModal.input.empty.error'))
      setIsEmailValid(false)
      hasErrors = true
    } else if (!emailRegex.test(email)) {
      setEmailError(t('demoModal.input.email.error'))
      setIsEmailValid(false)
      hasErrors = true
    } else {
      setEmailError('')
      setIsEmailValid(true)
    }

    if (!phone) {
      setPhoneError(t('demoModal.input.empty.error'))
      setIsPhoneValid(false)
      hasErrors = true
    } else if (!phoneRegex.test(phone)) {
      setPhoneError(t('demoModal.input.phone.error'))
      setIsPhoneValid(false)
      hasErrors = true
    } else {
      setPhoneError('')
      setIsPhoneValid(true)
    }

    if (!hasErrors) {
      const requestBody = {
        name,
        email,
        phone,
        lang: locale,
        company,
        site,
      }

      try {
        const response = await fetch('/landing/requestDemo', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }

        $amplitude('[Landing] Enrolment In Demo Success')
        handleOpenSuccessModal()
        onClose()
      } catch (error) {
        console.error('Error:', error)
      }
    }

    if (hasErrors) {
      const errorTypeName = () => {
        if (!isNameValid) {
          return 'name'
        }
        return ''
      }

      const errorTypeMail = () => {
        if (!isEmailValid) {
          return 'email'
        }
        return ''
      }

      const errorTypePhone = () => {
        if (!isPhoneValid) {
          return 'phone'
        }
        return ''
      }

      $amplitude('[Landing] Enrolment In Demo Failed', {
        empty_field: `${errorTypeName()} ${errorTypeMail()} ${errorTypePhone()}`,
      })
    }
  }

  if (!isOpen) return null

  const closeModal = () => {
    $amplitude('[Landing] Demo Modal Window Close')
    onClose()
  }

  const modalContent = (
    <div className={styles.layout} onClick={closeModal}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={closeModal}>
          &times;
        </button>
        <div className={styles.formSection}>
          <h1 className={styles.title}>{t('demoModal.title')}</h1>
          <h2 className={styles.description}>{t('demoModal.description')}</h2>
          <form className={styles.form} onSubmit={handleFormSubmit}>
            <div
              className={cns(styles.form__inputGroup, {
                [styles.form__input_error]: nameError,
              })}
            >
              <input
                className={styles.form__input}
                type="text"
                placeholder={t('demoModal.input.name.placeholder')}
                value={name}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
              />
              {isNameValid && (
                <span className={styles.form__iconSuccess}>
                  <CheckMark />
                </span>
              )}
              {nameError && (
                <span className={styles.form__iconError}>
                  <ErrorMark />
                </span>
              )}
            </div>
            {nameError && <div className={styles.form__error}>{nameError}</div>}
            <div
              className={cns(styles.form__inputGroup, {
                [styles.form__input_error]: emailError,
              })}
            >
              <input
                className={styles.form__input}
                type="email"
                placeholder={t('demoModal.input.email.placeholder')}
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
              />
              {isEmailValid && (
                <span className={styles.form__iconSuccess}>
                  <CheckMark />
                </span>
              )}
              {emailError && (
                <span className={styles.form__iconError}>
                  <ErrorMark />
                </span>
              )}
            </div>
            {emailError && (
              <div className={styles.form__error}>{emailError}</div>
            )}

            <div
              className={cns(styles.form__inputGroup, {
                [styles.form__input_error]: phoneError,
              })}
            >
              <input
                className={styles.form__input}
                type="tel"
                placeholder={t('demoModal.input.phone.placeholder')}
                value={phone}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
              />
              {isPhoneValid && (
                <span className={styles.form__iconSuccess}>
                  <CheckMark />
                </span>
              )}
              {phoneError && (
                <span className={styles.form__iconError}>
                  <ErrorMark />
                </span>
              )}
            </div>
            {phoneError && (
              <div className={styles.form__error}>{phoneError}</div>
            )}

            <div className={styles.form__inputGroup}>
              <input
                className={styles.form__input}
                type="text"
                placeholder={t('demoModal.input.company.placeholder')}
                value={company}
                onChange={handleCompanyChange}
              />
            </div>
            <div className={styles.form__inputGroup}>
              <input
                className={styles.form__input}
                type="text"
                placeholder={t('demoModal.input.site.placeholder')}
                value={site}
                onChange={handleSiteChange}
              />
            </div>
            <button
              className={cns(
                styles.form__button,
                `button`,
                `button--secondary`
              )}
            >
              {t('demoModal.enroll.button')}
            </button>
          </form>
        </div>
        <div classname={styles.buttonSection}>
          <button
            className={cns(
              styles.form__buttonMobile,
              `button`,
              `button--secondary`
            )}
            onClick={handleFormSubmit}
          >
            {t('demoModal.enroll.button')}
          </button>
          <h3 className={styles.footerText}>{t('demoModal.footer')}</h3>
        </div>
      </div>
    </div>
  )

  return ReactDOM.createPortal(modalContent, document.body)
}

export default DemoModal
