import { useState } from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import cns from 'classnames'

import { navDataLinksProductsAutoposting, navDataLinksProductsStatistika } from '@data/navData'

import useFormatMessage from '@utils/useFormatMessage'
import { getLinkPrefix } from '@utils/getLinkPrefix'

import IconDropdownArrow from '@icons/dropdown-arrow.svg'

import LinkSecondLevel from './linkSecondLevel'

import styles from './link.module.scss'

function Link({
  isMobile = false,
  isBlank,
  link,
  linkId,
  title,
  description,
  icon,
}) {
  const { locale } = useRouter()
  const t = useFormatMessage()

  const [open, setOpen] = useState(false)

  const openDropdown = e => {
    e.stopPropagation()
    isMobile && setOpen(!open)
  }

  const isSecondLevel = ['autoposting', 'statistika'].includes(linkId)

  const itemsForSNGCountries = [
    'autoposting-odnoklassniki',
    'avtoposting-vk',
    'vk-analytics',
    'statistika-odnoklassniki'
  ]

  const currentDropDawn = linkId === 'autoposting' ? navDataLinksProductsAutoposting : navDataLinksProductsStatistika

  return (
    <>
      <div
        className={cns(styles.root, isSecondLevel && styles.rootSecondLevel)}
      >
        {icon && <div className={styles.icon}>{icon}</div>}

        <div className={styles.textContent}>
          <span className={styles.textContent__title}>{title}</span>

          <span className={styles.textContent__description}>{description}</span>
        </div>

        {isSecondLevel && (
          <div className={styles.rightArrow} onClick={e => openDropdown(e)}>
            <IconDropdownArrow
              className={cns(
                styles.nav__ico,
                isMobile && !open && styles.nav__icoDawn,
                isMobile && open && styles.nav__icoUp
              )}
            />
          </div>
        )}

        <NextLink
          href={link}
          className={styles.link}
          target={isBlank && '_blank'}
        ></NextLink>

        {!isMobile && (
          <div className={styles.secondDropdown}>
            <div className={styles.transparent} />
            <div className={styles.bodySecondLevel}>
              {currentDropDawn.map(linkItem => {
                if (['en', 'es', 'br'].includes(locale)) {
                  if (itemsForSNGCountries.includes(linkItem.id)) {
                    return null
                  }
                }
                return (
                <LinkSecondLevel
                  key={linkItem.id}
                  id={linkItem.id}
                  title={t(`nav.links.${linkItem.id}`)}
                  description={t(`nav.descriptions.${linkItem.id}`)}
                  link={getLinkPrefix(locale, locale === 'br' ? linkItem.linkforBr : linkItem.link)}
                />
              )})}
            </div>
          </div>
        )}
      </div>

      {isSecondLevel && isMobile && (
        <div
          className={cns(
            open && styles.secondDropdownMobile,
            !open && styles.secondDropdownMobile_close
          )}
        >
          {currentDropDawn.map(linkItem => {
            if (['en', 'es', 'br'].includes(locale)) {
              if (itemsForSNGCountries.includes(linkItem.id)) {
                return null
              }
            }
            return (
            <LinkSecondLevel
              key={linkItem.id}
              id={linkItem.id}
              title={t(`nav.links.${linkItem.id}`)}
              description={t(`nav.descriptions.${linkItem.id}`)}
              link={getLinkPrefix(locale, locale === 'br' ? linkItem.linkforBr : linkItem.link)}
            />
          )})}
        </div>
      )}
    </>
  )
}

export default Link
