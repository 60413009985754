export const LINK_SIGNUP = '/auth/signUp'
export const LINK_SIGNIN = '/auth/signIn'
export const LINK_INVITE = '/auth/invite'
export const LINK_DEMO_FORM = {
  ru: 'https://forms.gle/TiDfZ3mDivuELr6x7',
  en: 'https://forms.gle/SU1eJKBCRMgRirx46',
  br: 'https://forms.gle/SU1eJKBCRMgRirx46',
  kz: 'https://forms.gle/TiDfZ3mDivuELr6x7',
  ua: 'https://forms.gle/TiDfZ3mDivuELr6x7',
}
export const FILES_PATH = 'files'

export const LANG_RU = 'ru'
export const LANG_BR = 'br'
export const LANG_ES = 'es'

export const LANGS_FOR_DEMO = [LANG_RU, LANG_BR]
