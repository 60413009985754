import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import cns from 'classnames'

import { languagesData } from '@data/languagesData'

import useFormatMessage from '@utils/useFormatMessage'
import $amplitude from '@utils/amplitude'

import IconArrow from '@icons/dropdown-arrow.svg'

import styles from './languageMenu.module.scss'

const getFlagIconSrc = currency => {
  const flagsMap = {
    ru: '/icons/flags/ru.svg',
    kz: '/icons/flags/kz.svg',
    en: '/icons/flags/en.svg',
    br: '/icons/flags/br.svg',
    ua: '/icons/flags/ua.svg',
    es: '/icons/flags/es.svg',
  }

  return flagsMap[currency]
}

function LanguageMenu() {
  const t = useFormatMessage()
  const buttonRef = useRef()
  const { locale, pathname, query } = useRouter()
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  useEffect(() => {
    const handleClick = event => {
      if (!buttonRef.current?.contains(event.target)) {
        setIsDropdownVisible(false)
      }
    }

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  const handleLinkClick = locale => {
    $amplitude('[Landing] Language Selection', {
      selected_type: locale.toUpperCase(),
    })
  }

  return (
    <div className={styles.root}>
      <button
        className={styles.button}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
        ref={buttonRef}
      >
        {locale}
        <IconArrow className={styles.button__arrow} />
      </button>

      <div
        className={cns(
          styles.dropdown,
          !isDropdownVisible && `visually-hidden`
        )}
      >
        <ul className={styles.dropdown__list}>
          {languagesData.map((lang, i) => (
            <li key={i}>
              <Link
                className={styles.dropdown__item}
                href={{ pathname, query }}
                locale={lang.locale}
                onClick={() => handleLinkClick(lang.locale)}
              >
                <img
                  className={styles.dropdown__item_icon}
                  src={getFlagIconSrc(lang.locale)}
                  alt={lang.locale}
                />
                {lang.locale}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default LanguageMenu
