import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cns from 'classnames'

import { useMatchMedia } from '@utils/useMatchMedia'

import Logo from '@components/layouts/logo/logo'
import Nav from '@components/layouts/nav/nav'
import Menu from '@components/layouts/menu/menu'
import MobileNav from '@components/layouts/mobileNav/mobileNav'

import styles from './header.module.scss'

export default function Header({ pageId }) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  const { isMobile } = useMatchMedia()

  useEffect(() => {
    const toggleHeader = () => {
      const scroll = window.scrollY
      if (scroll >= 10) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    toggleHeader()

    document.addEventListener('scroll', toggleHeader)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('scroll', toggleHeader)
    }
  }, [])

  const handleClick = () => {
    setIsMobileNavVisible(!isMobileNavVisible)
  }

  return (
    <>
      <header
        className={cns(
          styles.header,
          isScrolled && styles.header_scrolled,
          isMobileNavVisible && styles.header_open
        )}
      >
        <div className={cns(styles.header__wrapper)}>
          <div className={styles.header__logo}>
            <Logo className={styles.header__logoImage} />
          </div>
          <nav className={styles.header__menu}>
            {!isMobile && (
              <div className={styles.header__nav}>
                <Nav />
              </div>
            )}
            <div className={styles.header__actions}>
              <Menu
                pageId={pageId}
                handleClick={handleClick}
                isMobileNavVisible={isMobileNavVisible}
              />
            </div>
          </nav>
        </div>
      </header>

      {isMobile && <MobileNav pageId={pageId} isVisible={isMobileNavVisible} />}
      {/*<GoogleOneTap/>*/}
    </>
  )
}

Header.propTypes = {
  pageId: PropTypes.string,
}
