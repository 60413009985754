import { useRouter } from 'next/router'
import { LINK_SIGNIN, LINK_SIGNUP } from '@constants/index'

const useLanguageUrl = route => {
  const { locale } = useRouter()

  // const url = route === 'signin'
  //   ? `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNIN}`
  //   : locale === 'br'
  //     ? `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_INVITE}`
  //     : `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNUP}`

  const url =
    route === 'signin'
      ? `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNIN}`
      : `${process.env.NEXT_PUBLIC_PRO_SITE_URL}${LINK_SIGNUP}`

  return `${url}?language=${locale}`
}

export default useLanguageUrl
