export const languagesData = [
  {
    locale: 'en',
    link: '/en',
  },
  {
    locale: 'br',
    link: '/br',
  },
  {
    locale: 'ru',
    link: '/ru',
  },
  {
    locale: 'kz',
    link: '/kz',
  },
  {
    locale: 'ua',
    link: '/ua',
  },
  {
    locale: 'es',
    link: '/es',
  },
]
