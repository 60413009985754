import { useIntl } from 'react-intl'

const useFormatMessage = () => {
  const { messages, formatMessage } = useIntl()
  return (id, values = {}) => {
    let defaultValue = {}
    const idParts = id.split('.')
    const name = idParts[idParts.length - 1]

    const url = messages[id] ? findUrlRegex(messages[id]) : null

    return messages[id]
      ? url
        ? messages[id]
        : formatMessage({ id }, { ...values, ...defaultValue })
      : name
  }
}

const findUrlRegex = message => {
  const urlRegex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/gi

  return message.match(urlRegex)
}

export default useFormatMessage
