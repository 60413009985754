import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import NextLink from 'next/link'
import cns from 'classnames'

import { navDataLinks } from '@data/navData'
import useFormatMessage from '@utils/useFormatMessage'
import { getLinkPrefix } from '@utils/getLinkPrefix'
import { getIconFromMap } from '@utils/getIconFromMap'
import $amplitude from '@utils/amplitude'

import Link from '@components/layouts/nav/link/link'

import IconDropdownArrow from '@icons/dropdown-arrow.svg'

import styles from './nav.module.scss'

export default function Nav() {
  const t = useFormatMessage()
  const ref = useRef()
  const { locale } = useRouter()
  const [isOpenNav, setIsOpenNav] = useState(null)

  const openNav = id => {
    if (id === 'pricing') {
      $amplitude('[Landing] Tariff Tab Click')
    }
    setIsOpenNav(id)
  }

  const handleSubNavItemClick = (e, linkId, childLinkId) => {
    e.stopPropagation()

    switch (linkId) {
      case 'products':
        if (childLinkId === 'autoposting') {
          $amplitude('[Landing] Product Tab Click', {
            product_type: 'autoposting',
          })
        }
        if (childLinkId === 'account-stats') {
          $amplitude('[Landing] Product Tab Click', {
            product_type: 'statistics',
          })
        }
        if (childLinkId === 'reports') {
          $amplitude('[Landing] Product Tab Click', { product_type: 'reports' })
        }
        if (childLinkId === 'monitoring') {
          $amplitude('[Landing] Product Tab Click', {
            product_type: 'messages',
          })
        }
        break

      case 'resources':
        if (childLinkId === 'researches') {
          $amplitude('[Landing] Resources Tab Click', {
            product_type: 'researches',
          })
        }
        if (childLinkId === 'blog') {
          $amplitude('[Landing] Resources Tab Click', { product_type: 'Blog' })
        }
        if (childLinkId === 'knowledgeBase') {
          $amplitude('[Landing] Resources Tab Click', {
            product_type: 'Knowledge Base',
          })
        }
        if (childLinkId === 'YTRating') {
          $amplitude('[Landing] Resources Tab Click', {
            product_type: 'YouTube rating',
          })
        }
        if (childLinkId === 'VKRating') {
          $amplitude('[Landing] Resources Tab Click', {
            product_type: 'VKontakte rating',
          })
        }
        if (childLinkId === 'LJRating') {
          $amplitude('[Landing] Resources Tab Click', {
            product_type: 'LiveJournal rating',
          })
        }

        break

      case 'tools':
        if (childLinkId === 'kpi') {
          $amplitude('[Landing] Tools Tab Click', { product_type: 'kpi' })
        }
        if (childLinkId === 'bloggersCheck') {
          $amplitude('[Landing] Tools Tab Click', {
            product_type: 'check_bloggers',
          })
        }
        if (childLinkId === 'expressAuditing') {
          $amplitude('[Landing] Tools Tab Click', {
            product_type: 'express_audit',
          })
        }
        break
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenNav(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return (
    <nav>
      <ul className={styles.nav__list} ref={ref}>
        {navDataLinks.map(link => {
          if (locale === 'br') {
            if (link.id === 'resources') {
              return null
            }
          }

          return link.id === `languages` ? null : (
            <li
              className={cns(
                styles.nav__item,
                link.pages && styles.nav__item__with_dropdown,
                isOpenNav === link.id && styles.nav__item__open,
                link.id === 'demoFull' && styles.nav__item_demo
              )}
              onClick={() => openNav(link.id)}
              key={`nav_${link.id}`}
            >
              {link.pages ? (
                <>
                  <div className={styles.nav__link}>
                    {t(`nav.links.${link.id}`)}
                    <IconDropdownArrow className={styles.nav__ico} />
                  </div>

                  <div className={cns(styles.nav_dropdown)}>
                    <ul className={styles.nav_dropdown__list}>
                      {link.pages.length &&
                        link.pages.map(childLink => {
                          if(locale !== 'br' && childLink.id === 'faq') {
                            return null;
                          }
                          if (
                            locale === 'en' ||
                            locale === 'br' ||
                            locale === 'es'
                          ) {
                            if (
                              childLink.id === 'YTRating' ||
                              childLink.id === 'VKRating' ||
                              childLink.id === 'LJRating' ||
                              childLink.id === 'knowledgeBase' ||
                              childLink.id === 'researches'
                            ) {
                              return null
                            }
                          }
                          if (locale === 'br') {
                            if (
                              childLink.id === 'vk-analytics' ||
                              childLink.id === 'statistika-odnoklassniki'
                            ) {
                              return null
                            }
                          }
                          return (
                            <li
                              className={styles.nav_dropdown__item}
                              key={`link_${childLink.id}`}
                              onClick={e =>
                                handleSubNavItemClick(e, link.id, childLink.id)
                              }
                            >
                              <Link
                                link={
                                  locale === 'en' && childLink.id === 'blog'
                                    ? childLink.linkforEn
                                    : locale === 'br' && childLink.id === 'blog'
                                    ? childLink.linkforBr
                                    : locale === 'es' && childLink.id === 'blog'
                                    ? childLink.linkforEs
                                    : getLinkPrefix(locale, locale === 'br' ? childLink.linkforBr : childLink.link)
                                }
                                title={t(
                                  `nav.links.${link.id}.${childLink.id}`
                                )}
                                description={t(
                                  `nav.descriptions.${link.id}.${childLink.id}`
                                )}
                                linkId={childLink.id}
                                icon={getIconFromMap(childLink.id)}
                                isBlank={childLink.isBlank}
                              />
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </>
              ) : locale !== 'br' && link.id === 'Blog' ? null : (
                <NextLink
                  className={styles.nav__link}
                  href={locale !== 'br' ? getLinkPrefix(locale, link.link) : getLinkPrefix(locale, link.linkforBr)}
                >
                  {t(`nav.links.${link.id}`)}
                </NextLink>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
