import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import cns from 'classnames'

import { LANGS_FOR_DEMO } from '@constants/index'

import $amplitude from '@utils/amplitude'
import useFormatMessage from '@utils/useFormatMessage'

import { navDataLinks } from '@data/navData'

import Item from '@components/layouts/mobileNav/item/item'
import Actions from '@components/layouts/mobileNav/actions/actions'
import LangSwitcher from '@components/layouts/mobileNav/langSwitcher/langSwitcher'
import DemoModal from '@components/demoModal/DemoModal/DemoModal'
import SuccessModal from '@components/demoModal/SuccesModal/SuccessModal'

import stylesItem from './item/item.module.scss'
import styles from './mobileNav.module.scss'

export default function MobileNav({ pageId, isVisible }) {
  const t = useFormatMessage()
  const { locale } = useRouter()

  const [openedIdx, setOpenedIdx] = useState(null)
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const isShowDemo = LANGS_FOR_DEMO.includes(locale)

  const handleClick = idx => {
    setOpenedIdx(openedIdx === idx ? null : idx)
  }

  const handleOpenDemoModal = () => {
    setIsDemoModalOpen(true)
    $amplitude('[Landing] Demo Button Click', { page_name: pageId })
    $amplitude('[Landing] Demo Modal Window View')
  }

  const handleCloseDemoModal = () => {
    setIsDemoModalOpen(false)
  }

  const handleOpenSuccessModal = () => {
    setIsSuccessModalOpen(true)
  }
  const handleCloseSuccessModal = () => setIsSuccessModalOpen(false)

  useEffect(() => {
    const body = document.body

    if (isVisible) body.classList.add(`body--fixed`)
    else body.classList.remove(`body--fixed`)
  }, [isVisible])

  return (
    <div
      id="mobile-nav"
      className={cns(styles.mobileNav, isVisible && styles.mobileNav__open)}
    >
      <div className={styles.mobileNav__content}>
        <p className={styles.mobileNav__label}>{t(`menu.title`)}</p>

        <ul>
          {navDataLinks.map((link, i) => {
            if (locale === 'br') {
              if (link.id === 'resources') {
                return null
              }
            }
            return (
              <Item
                isDropDownVisible={openedIdx === i}
                link={link}
                key={i}
                handleClick={() => handleClick(i)}
              />
            )
          })}

          {isShowDemo && (
            <div className={stylesItem.mobileNav__item}>
              <button
                className={stylesItem.mobileNav__link}
                onClick={handleOpenDemoModal}
              >
                {t(`nav.links.demo`)}
              </button>
            </div>
          )}

          {isDemoModalOpen && (
            <DemoModal
              isOpen={isDemoModalOpen}
              locale={locale}
              handleOpenSuccessModal={handleOpenSuccessModal}
              onClose={handleCloseDemoModal}
            />
          )}
          {isSuccessModalOpen && (
            <SuccessModal
              isOpen={isSuccessModalOpen}
              onClose={handleCloseSuccessModal}
            />
          )}
        </ul>
        <LangSwitcher />
      </div>

      <div className={styles.mobileNav__actions}>
        <Actions pageId={pageId} />
      </div>
    </div>
  )
}

MobileNav.propTypes = {
  isVisible: PropTypes.bool,
  pageId: PropTypes.string,
}
