import { useRouter } from 'next/router'
import Link from 'next/link'
import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'
import { getLinkPrefix } from '@utils/getLinkPrefix'

import styles from './logo.module.scss'

function Logo({ className }) {
  const t = useFormatMessage()
  const { locale } = useRouter()

  return (
    <Link href={getLinkPrefix(locale, '/')}>
      <img
        className={cns(styles.img, className)}
        src='/images/logo.svg'
        alt={t(`logo.alt`)}
      />
    </Link>
  )
}

export default Logo
