import { useRouter } from 'next/router'
import cns from 'classnames'

import useFormatMessage from '@utils/useFormatMessage'
import DropdownArrow from '@icons/dropdown-arrow.svg'

import { getLinkPrefix } from '@utils/getLinkPrefix'
import { getIconFromMap } from '@utils/getIconFromMap'
import Link from '@components/layouts/nav/link/link'

import stylesNav from '../../nav/nav.module.scss'
import styles from './item.module.scss'
import NextLink from 'next/link'

export default function Item({ link, isDropDownVisible, handleClick }) {
  const { locale } = useRouter()
  const t = useFormatMessage()

  return (
    <li
      className={cns(
        styles.mobileNav__item,
        link.pages && stylesNav.nav__item__with_dropdown,
        link.pages && isDropDownVisible && styles[`mobileNav__item--open`],
        link.id === `languages` && styles.mobileNav__item_lang,
        link.id === `demoFull` && styles.mobileNav__item_demo
      )}
    >
      {link.pages ? (
        <div className={styles.mobileNav__link} onClick={handleClick}>
          {t(`nav.links.${link.id}`)}

          <DropdownArrow className={styles.mobileNav__ico} />
        </div>
      ) : locale !== 'br' && link.id === 'Blog' ? null : (
        <NextLink
          className={styles.mobileNav__link}
          href={getLinkPrefix(
            locale,
            locale === 'br' ? link.linkforBr : link.link
          )}
          target={link.isBlank && '_blank'}
        >
          {t(`nav.links.${link.id}`)}
        </NextLink>
      )}

      {link.pages && (
        <div
          className={cns(styles.mobileNav_dropdown, styles.mobileNav_dropdown)}
        >
          <ul className={styles.mobileNav_dropdown__list}>
            {link.pages &&
              link.pages
                .filter(linkId =>
                  locale !== 'br' ? linkId.id !== 'faq' : true
                )
                .map((linkPage, i) => {
                  if (locale === 'en' || locale === 'br' || locale === 'es') {
                    if (
                      linkPage.id === 'YTRating' ||
                      linkPage.id === 'VKRating' ||
                      linkPage.id === 'LJRating' ||
                      linkPage.id === 'TGRating' ||
                      linkPage.id === 'knowledgeBase' ||
                      linkPage.id === 'researches'
                    ) {
                      return null
                    }
                  }
                  if (locale === 'br') {
                    if (
                      linkPage.id === 'vk-analytics' ||
                      linkPage.id === 'statistika-odnoklassniki'
                    ) {
                      return null
                    }
                  }
                  return (
                    <li key={i}>
                      <Link
                        isMobile={true}
                        isBlank={linkPage.isBlank}
                        link={
                          locale === 'en' && linkPage.id === 'blog'
                            ? linkPage.linkforEn
                            : locale === 'br' && linkPage.id === 'blog'
                            ? linkPage.linkforBr
                            : getLinkPrefix(
                                locale,
                                locale === 'br'
                                  ? linkPage.linkforBr
                                  : linkPage.link
                              )
                        }
                        title={t(`nav.links.${link.id}.${linkPage.id}`)}
                        description={t(
                          `nav.descriptions.${link.id}.${linkPage.id}`
                        )}
                        icon={getIconFromMap(linkPage.id)}
                        linkId={linkPage.id}
                      />
                    </li>
                  )
                })}
          </ul>
        </div>
      )}
    </li>
  )
}
