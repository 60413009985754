import { useRouter } from 'next/router'
import Link from 'next/link'
import cns from 'classnames'

import { languagesData } from '@data/languagesData'
import useFormatMessage from '@utils/useFormatMessage'

import styles from './langSwitcher.module.scss'

export default function LangSwitcher() {
  const { locale, pathname, query } = useRouter()
  const t = useFormatMessage()

  return (
    <div className={styles.languageSwitcher}>
      {languagesData.map((lang, i) =>
        lang.locale === locale ? (
          <p className={cns(styles.option, styles.option_current)} key={i}>
            {lang.locale}
          </p>
        ) : (
          <Link
            className={styles.option}
            href={{ pathname, query }}
            locale={lang.locale}
            key={i}
          >
            {lang.locale}
          </Link>
        )
      )}
    </div>
  )
}
